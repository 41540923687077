<template>
  <div style="min-width: 1200px;">
    <headea></headea>
    <div class="top-img">
      <div class="pyt"><img src="../../assets/images/cpfw.jpg" alt="" /></div>
      <div class="top-tit">
        <div>应用场景</div>
        <!-- <div>资 深 行 业 解 决 方 案<span></span> 助 力 企 业 快 速 发 展</div> -->
      </div>
    </div>
    <div class="cptit">应用场景</div>
    <div class="pyta"><img src="../../assets/images/yycj2.png" alt="" /></div>
    <!-- <div class="jz">装配式建筑是传统建筑行业向工业化制造升级的必然方向</div>
    <div class="jz">是普及绿色建筑，推进高质量发展的重要组成部分</div> -->

    <!-- <div class="conotbox">
      <div class="imgbox" v-for="(item, index) in this.list" :key="index">
        <img :src="item.imgIcon" alt="" />
        <div class="boxcontent">
          <div class="boxtitle">{{ item.title }}</div>
          <div class="boxtext">{{ item.brief }}</div>
         
        </div>
        <div class="ypbox">
          <div @click="fw(item.id, index)">了解详情</div>
        </div>
      </div>
    </div> -->

    <sild></sild>
    <bottom></bottom>
  </div>
</template>

<script>
import headea from "../../components/header/index";
import sild from "../../components/sild/side";
import bottom from "../../components/bottom/bottom";
import { productgetPage } from "../../api/api";
export default {
  components: {
    headea,
    sild,
    bottom,
  },
  data() {
    return {
      list: [
       
      ],
    };
  },
  created() {
    this.bannera();
  },
  methods: {
    fw(e, i) {
      this.$router.push({
        path: "/fw",
        query: {
          tape: 0,
        },
      });
      sessionStorage.setItem("fw", e);
    },
    async bannera() {
      let param = {
        pageNo: 1,
        pagesize: 100,
      };
      const { data: res } = await productgetPage(param);
      this.list = res.data.list;
      // this.totale = res.data.total;
    },
  },
};
</script>

<style lang="less" scoped>
.pyta {
  width: 1360px;
  margin: 0 auto;
  img {
    width: 100%;
  }
}
.top-img {
  width: 1360px;
  margin: 0 auto;
  height: 600px;
  min-width: 1200px;
  position: relative;
  margin-top: 120px;
  max-height: 38rem;
  overflow: hidden;
  min-height: 700px;
  img {
    width: 100%;
    height: 37.625rem /* 650/16 */ /* 670/16 */ /* 650/16 */;
    min-height: 650px;
  }
  .top-tit {
    position: absolute;
    width: 1200px;
    top: 35%;
    text-align: center;
    left: 50%;
    margin-left: -600px;
    color: #ffffffff;
    font-size: 32px;
    padding: 0 20px;
    div:nth-child(1) {
      font-size: 70px;
      font-weight: 800;
    }
    div:nth-child(2) {
      margin-top: 6px;
      span {
        display: inline-block;
        width: 30px;
      }
    }
  }
}
.cptit {
  font-size: 30px;
  color: #171c61ff;
  font-weight: 600;
  width: 1200px;
  height: 45px;
  margin: 0 auto;
  margin-bottom: 24px;
}
.jz {
  font-size: 24px;
  color: #707070ff;
  width: 1200px;
  margin: 0 auto;
  text-align: center;
}
.conotbox {
  width: 1200px;
  margin: 0 auto;
  margin-top: 42px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 120px;
}
.imgbox {
  width: 384px;
  height: 430px;
  border: 1px solid #efefefff;
  margin-right: 24px;
  margin-bottom: 24px;
  position: relative;
}
.imgbox:hover .ypbox {
  display: block;
}
.ypbox {
  background-color: #000b8838;
  border: 3px solid #000b88;
  display: none;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 384px;
  height: 430px;
  transition: all 0.9s;
  -moz-transition: all 0.9s;
  -webkit-transition: all 0.9s;
  -o-transition: all 0.9s;
}
.ypbox div {
  color: #fff;
  background-color: #000b88;
  width: 142px;
  height: 42px;
  text-align: center;
  font-size: 18px;
  line-height: 42px;
  border-radius: 30px;
  margin: 0 auto;
  margin-top: 153px;
  cursor: pointer;
  transition: all 0.9s;
  -moz-transition: all 0.9s;
  -webkit-transition: all 0.9s;
  -o-transition: all 0.9s;
}
.imgbox:nth-child(3n + 3) {
  margin-right: 0;
}
.imgbox img {
  width: 384px;
  height: 289px;
}
.boxcontent {
  padding: 0 50px;
}
.boxtitle {
  margin-top: 30px;
  font-size: 18px;
  text-align: center;
  color: #171c61ff;
  font-weight: 600;
}
.boxtext {
  font-size: 14px;
  word-wrap: break-word;
  margin-top: 15px;
  text-align: center;
  color: #afafafff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.boxtexta {
  font-size: 14px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  color: #afafafff;
}
</style>
